.column-type{
    width: 85px;
}
.column-total{
    width: 150px;
}
.column-sum{
    width: 150px;
}
.column-price{
    width: 130px;
}
.column-quantity{
    width: 150px;
}
.select-style.custom-select-order-book-filter .ant-select-selector{
    border-radius: 8px;
}
.custom-book-select-container{
    position: relative;
}
.custom-book-select-container .custom-select-placeholder{
    position: absolute;
    background: #2b2b36;
    top: 9px;
    left: 12px;
}
