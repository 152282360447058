.my-table .ant-table-thead .ant-table-cell {
    border-bottom: unset;
}

.my-table .ant-table-thead .ant-table-cell::before {
    display: none;
}

.my-table {
    border-collapse: collapse !important;
    scrollbar-gutter: stable;
}

.my-table::-webkit-scrollbar {
    position: relative;
    width: 4px;
    color: #F3F3F8;
}

.my-table::-webkit-scrollbar-track {
    background-color: transparent;
}

.my-table::-webkit-scrollbar-thumb {
    background: #F3F3F8;
    border-radius: 4px;
}

.my-table .ant-table-tbody > tr > td {
    border: none
}

.ant-table-body::-webkit-scrollbar{
    width: 4px;
    color: #F3F3F8;
}

.ant-table-body::-webkit-scrollbar-track{
    background-color: transparent;
}

.ant-table-body::-webkit-scrollbar-thumb{
    background: #F3F3F8;
    border-radius: 4px;
}
